@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}
:root {
  --color_bg: #272727;
  --color_bg_variant: #555555af;
  --color_primary: #95d600;
  --color_primary_variant: #84b907b7;
  --color_white: #fff;
  --color_light: rgba(255, 255, 255, 0.6);
  --color_dark: #161616;
  --tansition: all 400ms ease;
  --container_width_lg: 75%;
  --container_width_md: 86%;
  --container_width_sm: 90%;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: var(--color_dark);
}
::-webkit-scrollbar-thumb {
  background: var(--color_primary);
  border-radius: 6px;
  transition: all 0.5s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--color_primary_variant);
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  background: var(--color_bg);
  color: var(--color_white);
  line-height: 1.7;
  background: var(--color_bg);
}
/* ============== general styles============= */
.container {
  width: var(--container_width_lg);
  margin: 0 auto;
  
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}
h1 {
  font-size: 25rem;
}
section {
  margin-top: 8rem;
  min-height: 100vh;
}
section > h2,
section > h5 {
  text-align: center;
  color: var(--color_light);
}
section > h2 {
  color: var(--color_primary);
  margin-bottom: 3rem;
}
.text-light {
  color: var(--color_light);
}
a {
  color: var(--color_primary);
  transition: var(--tansition);
}
a:hover {
  color: var(--color_white);
}
.btn {
  width: max-content;
  display: inline-block;
  color: var(--color_primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color_primary);
  transition: var(--tansition);
  font-weight: bold;  
}
.btn:hover {
  background: var(--color_white);
  color: var(--color_bg);
  border-color: transparent;
}
.btn-primary {
  background: var(--color_primary);
  color: var(--color_bg);
}
img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ================ media quaries medium =========== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container_width_md);
  }
  section {
    margin-top: 6rem;
  }
  @media screen and (max-width: 600px) {
    .container {
      width: var(--container_width_sm);
    }
    section {
      margin-top: 2rem;
    }
    section > h2 {
      margin-bottom: 2rem;
    }
  }
}
