.wrapper {
  height: 120vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #161616;
  z-index: 99;
}
.wrapper .static-txt {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}
.wrapper .dynamic-txts {
  margin-left: 15px;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
}
.dynamic-txts li {
  list-style: none;
  color: #95d600;
  font-size: 30px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slide 8s steps(4) infinite;
}
@keyframes slide {
  100% {
    top: -360px;
  }
}
.dynamic-txts li span {
  position: relative;
  margin: 5px 0;
  line-height: 45px;
}
.dynamic-txts li span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: #161616;
  border-left: 2px solid #95d600;
  animation: typing 2s steps(10) infinite;
}
@keyframes typing {
  40%,
  60% {
    left: calc(100% + 30px);
  }
  100% {
    left: 0;
  }
}

.__loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: #161616;
    color: #95d600;
    font-size: 30px;
    font-weight: bold;
    z-index: 10;
}