header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
  background: var(--color_dark);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 87%, 0 100%);
}
.flex {
  display: flex;
}
.flex_one {
  flex: 1;
}
.items_center {
  align-items: center;
}
.justify_between {
  justify-content: space-between;
}
.justify_center {
  justify-content: center;
}
.hero {
  padding: 3rem 0;
  height: 100%;
  width: 100%;
}
.hero .left img {
  width: 400px;
}
.hero .right {
  color: var(--color_white);
  margin-top: -7rem;
}
.hero .right h6 {
  font-size: 1.6rem;
  color: var(--color_primary);
  margin-bottom: 0.5rem;
}
.hero .right h1 {
  font-size: 3rem;
  font-weight: 100;
  line-height: 1.2;
  margin-bottom: 1rem;
  /* font-weight: bold; */
}
.hero .right h1 span {
  color: var(--color_primary);
  font-weight: bold;
}
.hero .right p {
  line-height: 1.9;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.header___container {
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}
.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color_primary);
}
.me {
  background: linear-gradient(var(--color_primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  header {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 27% 89%, 0 100%);
  }
  .hero {
    padding-top: 10rem;
  }
  .hero .left img {
    width: 260px;
  }
  .hero .right h1 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 600px) {
  header {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 69% 96%, 0 100%);
    padding-top: 1rem;
  }
  .hero {
    flex-direction: column;
  }
  .hero .left {
    margin-top: -5rem;
    margin-right: -9rem;
    padding-bottom: 4rem;
  }
  .hero .right {
    position: absolute;
    top: 260px;
    padding: 0 1rem;
    padding-bottom: 100%;
    margin-left: 33px
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
 
}
