#testimonials{
    background: var(--color_dark);
    padding: 50px 0;
}
.testimonials__container{
    width: 40%;
    padding-bottom: 4rem !important;
}
.client__avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color_primary_variant);
}
.tesitmonial{
    background: var(--color_bg);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}
.client__name{
    font-size: 16px;
    font-weight: bold;
}

.client__review{
    color: var(--color_light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0; 
}

.swiper-pagination-bullet, .swiper-pagination-bullet-active{
    background: var(--color_primary) !important;
}
@media screen and (max-width: 1024px) {
    .testimonials__container{
        width: 60%;
    }   
}

@media screen and (max-width: 600px) {
    .testimonials__container{
        width: var(--container_width_sm);
    }
    .client__review{
        width: var(--container_width_sm);
    }
}