nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    top: 35%;
    transform: translateX(-50%);
    right: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    color: var(--color_light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color_primary);
    color: var(--color_bg);
}

@media screen and (max-width: 600px) {
    nav {

        position: fixed;
        flex-direction: row;
        top: auto;
        left: 48% !important;
        bottom: 2rem !important;
    }
}