.page_not_found{
 width: 100%;
 height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}
.page_not_found h1.text_not_found{
    font-size: 40px !important;
    color: var(--color_primary);
    text-align: center;
}
.page_not_found .back_to_home{
    border-radius: 5px;
    border: 2px solid var(--color_primary);
    padding: 5px 10px;
}
.page_not_found .back_to_home:hover{
    border-color: var(--color_light);
}