.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
    align-items: center;
}
.contact__options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact__option{
    background: var(--color_bg_variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
.contact__option:hover{
    background: transparent;
    border-color: var(--color_primary_variant);
}
.contact__option__icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}


.contact__container form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact__container input, .contact__container textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color_primary_variant);
    resize: none;
    color: var(--color_white);
    
}
.contact__container textarea::placeholder{
    font-family: "Poppins", sans-serif;

}
.invInput{
    border-color: #ff6600 !important;
}
.pointer{
    cursor: pointer !important;
}
@media screen and (max-width: 1024px) {
    .contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
@media screen and (max-width: 600px) {
    .contact__container{
        width: var(--container_width_sm);
    }
}