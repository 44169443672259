.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color_bg_variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transform: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color_bg_variant);
    background: transparent;
}

.portfolio__item__image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item__image img {
    height: 130px !important;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    height: 90px;
}

.portfolio__item h5 {
    text-align: center;
    padding: 10px 0;
}

.portfolio__item__cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__item__cta a {
    width: 100%;
    text-align: center;
}

.portfolio__item__cta a:nth-child(2) {
    background: var(--color_primary);
    color: var(--color_dark);
}

.portfolio__item__cta a:nth-child(2):hover {
    background: var(--color_white);
    color: var(--color_dark);

}

.portfolio__item__cta a:nth-child(3) {
    background: #fe6e0e;
    color: var(--color_dark);
    border-color: #fe6e0e;
}

.portfolio__item__cta a:nth-child(3):hover {
    background: var(--color_white);
    color: var(--color_dark);

}

@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}